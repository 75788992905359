@import '../../styles/customMediaQueries.css';

.pageRoot {
  position: relative;
  overflow-x: hidden;
}

.profileVerification {
  position: fixed;
  top: 80px;
  right: 0;
  left: 0;
  z-index: 1;
  max-width: calc(100% - 20px);
  width: 100%;
  padding: 10px 20px;
  border-radius: 6px;
  background: var(--marketplaceColorSecondary);
  color: var(--colorWhite);
  box-shadow: var(--boxShadow);
  margin: 0 auto;
  font-size: 15px;
  font-weight: 400;

  @media (--viewportLarge) {
    top: 100px;
    max-width: var(--contentMaxWidth);
  }

  & > span {
    color: var(--colorWhite);
    top: 10px;
    position: absolute;
    right: 10px;
    font-size: 30px;
    font-weight: 300;
  }
}

.heroContainer {
  background-image: url('../../assets/landing-hero-bg.png');
  background-position: bottom center;
  padding-bottom: 86px;
  background-repeat: no-repeat;
  background-color: var(--marketplaceColor);
  position: relative;
  padding-left: 24px;
  padding-right: 24px;

  &::before {
    position: absolute;
    content: '';
    background-image: url('../../assets/landing-hero-shape1.png');
    background-position: bottom center;
    background-repeat: no-repeat;
    width: 188px;
    height: 100%;
    bottom: 0;
    left: 0;
    z-index: 0;
  }

  &::after {
    position: absolute;
    content: '';
    background-image: url('../../assets/landing-hero-shape2.png');
    background-position: bottom center;
    background-repeat: no-repeat;
    width: 156px;
    height: 100%;
    bottom: 0;
    right: 0;
    z-index: 0;
  }
}

.contentWidth {
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.featuresBox {
  background-color: #fbdec0;
  padding: 20px 40px;
  margin: 20px 0;
  @media (--viewportMedium) {
    padding: 100px 150px;
  }
}

.pageContent {
  width: 100%;

  & .heroContainer {
    & .contentWidth {
      padding-top: 130px;
      position: relative;
      z-index: 1;

      @media (--viewportMedium) {
        padding-top: 170px;
      }
    }
  }
}

.sticky {
}

.lowerElement {
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: center;
  align-items: center;
}

.askTitle {
  font-size: 24px;
  font-weight: var(--fontWeightBold);
  line-height: 36px;
  text-align: center;
  color: var(--colorBlack);
}

.centerText {
  width: 100%;
}

.centerText p {
  text-align: center !important;
}

.submitButtonGreen {
  background-color: var(--matterGreenColor);
  color: var(--colorWhite);
  border: solid 1px var(--matterGreenColor);
  border-radius: 12px;
  padding: 16px 30px;
  cursor: pointer;
  transition: var(--transitionStyle);
  width: max-content;

  @media (--viewportSmall) {
    width: max-content;
  }

  &:hover {
    transition: var(--transitionStyle);
    background-color: var(--matterDarkGreenColor);
    border: solid 1px var(--matterDarkGreenColor);
    color: var(--colorWhite);
  }
}

.submitButton {
  background-color: var(--marketplaceColor);
  color: var(--colorWhite);
  border: solid 1px var(--marketplaceColor);
  border-radius: 12px;
  padding: 16px 30px;
  cursor: pointer;
  transition: var(--transitionStyle);
  width: max-content;

  @media (--viewportSmall) {
    width: max-content;
  }

  &:hover {
    transition: var(--transitionStyle);
    background-color: var(--marketplaceColorDark);
    border: solid 1px var(--marketplaceColorDark);
    color: var(--colorWhite);
  }
}

.featuresContainer {
  background-color: var(--matterPurpleColor);
  width: 100%;
  height: auto;
  border-radius: 24px;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 36px;
  padding: 20px;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: unset;
    width: 100%;
    padding: 37px 41px;
  }

  & .featuresLeft {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;

    @media (--viewportMedium) {
      width: 30%;
    }

    & h1 {
      font-weight: var(--fontWeightBold);
      font-size: 35px;
      margin: 0px;

      & span {
        font-size: 18px;
      }
    }

    & h2 {
      font-weight: var(--fontWeightSemiBold);
      font-size: 30px;
    }

    & .registerNowBtn {
      margin-top: 40px;

      & .registerBtn {
        background-color: var(--colorWhite);
        border: solid 1px var(--colorWhite);
        color: var(--colorBlack);
        border-radius: 6px;
        font-weight: var(--fontWeightSemiBold);
        font-size: 14px;
        line-height: 21px;
        /* text-transform: uppercase; */
        cursor: pointer;
        padding: 14px 20px;
        transition: var(--transitionStyle);

        @media (--viewportMedium) {
          flex-direction: row;
          min-width: 250px;
          gap: unset;
          padding: 14px 20px;
        }

        @media (--viewportLarge) {
          flex-direction: row;
          min-width: 250px;
          gap: unset;
          padding: 14px 40px;
        }

        &:hover {
          background-color: var(--marketplaceColor);
          border-color: var(--marketplaceColor);
          color: var(--colorWhite);
          transition: var(--transitionStyle);
        }
      }
    }
  }

  & .featuresRight {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 20px;

    @media (--viewportMedium) {
      width: 70%;
    }

    & h2 {
      color: var(--colorWhite);
      font-size: 21px;
      font-weight: var(--fontWeightMedium);
      margin: 0;
      text-align: left;
    }

    & ul {
      list-style: none;
      padding: 0;
    }

    & li {
      position: relative;
      font-size: 16px;
      padding-left: 30px;
      margin-bottom: 10px;
      display: flex;
      text-align: left;
      align-items: center;
    }

    & li::before {
      content: '';
      position: absolute;
      left: 0;
      top: 17px;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      background-image: url('../../assets/checkmark.png');
      background-size: contain;
      background-repeat: no-repeat;

      @media (--viewportMedium) {
        top: 21px;
      }
    }
  }
}

.programsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 50px;
  }
}

.programButton {
  background-color: var(--matterPurpleColor);
  text-align: center;
  color: var(--colorWhite);
  border: solid 1px var(--colorWhite);
  border-radius: 100px;
  padding: 20px 50px;
  cursor: pointer;
  transition: var(--transitionStyle);
}

.heroContent {
  text-align: center;
  max-width: 752px;
  width: 100%;
  margin: 0 auto;

  & > h1 {
    color: var(--colorWhite);
    font-weight: var(--fontWeightBold);
    font-size: 35px;
    line-height: 48px;
    margin: 0 auto 27px auto;
    max-width: 618px;
    width: 100%;

    @media (--viewportLarge) {
      font-size: 45px;
      line-height: 68px;
      margin: 0 auto 37px auto;
    }
  }

  & > p {
    color: var(--colorWhite);
    font-weight: var(--fontWeightMedium);
    font-size: 18px;
    line-height: 25px;
    margin: 0 auto 28px auto;
    max-width: 618px;
    width: 100%;

    @media (--viewportLarge) {
      font-size: 20px;
      line-height: 30px;
    }
  }
}

.answersSection {
  padding: 24px;
  position: relative;
  background-color: #fff6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.answersSectionTabs {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  width: 100%;

  @media (--viewportMedium) {
    width: 100%;
    flex-direction: row;
    max-width: var(--contentMaxWidthPages);
    margin: 0 auto;
  }
}

.answersSectionTopics {
  flex: 1;
}

.answersSectionExperts {
  flex: 1;
}

.answerSeparator {
  width: 1px;
  height: auto;
  background-color: black;
  margin: 0 24px;
}

.textCenter {
  text-align: center;
}

.answersSectionList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
}

.answersExpertTitle {
  color: #835358;
  text-align: center;
}

.answersTopicTitle {
  color: var(--matterPurpleColor);
  text-align: center;
}

.answerExpertItem {
  border: 1px solid #835358;
  border-radius: 48px;
  padding: 16px;
  gap: 2px;
  width: max-content;
}

.answerTopicItem {
  border: 1px solid var(--matterPurpleColor);
  border-radius: 48px;
  padding: 16px;
  gap: 2px;
  width: max-content;
}

.aboutSection {
  padding: 24px;
  position: relative;
  background-color: #fff6f6;

  & .bestExpert {
    max-width: 820px;
    padding: 40px 0px;
    width: 100%;
    color: var(--marketplaceColorSecondary);
    font-size: 30px;
    line-height: 40px;
    margin: 0 auto;
    text-align: center;
    font-weight: var(--fontWeightMedium);
    font-family: initial;

    @media (--viewportMedium) {
      font-size: 38px;
      line-height: 48px;
      padding: 40px 20px;
    }

    @media (--viewportLarge) {
      font-size: 48px;
      line-height: 58px;
    }

    & > span {
      background-color: hsl(22deg 82% 73% / 25%);
    }
  }

  & h1 {
    color: var(--marketplaceColor);
    font-weight: var(--fontWeightBold);
    font-size: 35px;
    line-height: 58px;
    text-align: center;
    margin: 37px 0;

    @media (--viewportMedium) {
      margin: 30px 0;
    }

    @media (--viewportLarge) {
      font-size: 45px;
      line-height: 68px;
    }
  }

  & .howItWorks {
    display: flex;
    justify-content: center;
    /* Center the items horizontally */
    align-items: stretch;
    /* Makes all child elements the same height */
    flex-wrap: wrap;
    /* Allows the items to wrap to the next line if necessary */
    /* Sets the maximum width for the container */
    flex-direction: column;
    gap: 15px;

    @media (--viewportMedium) {
      flex-direction: row;
      max-width: 1250px;
    }

    & .workBlock {
      border-radius: 8px;
      border: 2px solid var(--marketplaceColor);
      flex: 1;
      text-align: center;
      height: auto;
      padding: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (--viewportMedium) {
        padding: 30px;
        max-width: 640px;
      }

      & .upperElement {
        display: flex;
        flex-direction: column;

        & h2 {
          font-size: 24px;
          margin: auto;
        }
      }

      & .middleElement {
        display: flex;
        flex: 1;
        height: auto;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: auto;
      }

      & .lowerElement {
        display: flex;
        flex-direction: column;
        height: 100px;
        justify-content: center;
        align-items: center;
      }

      & .workImg {
        margin-bottom: 10px;
        margin-top: 20px;
        @media (--viewportMedium) {
          margin-top: 40px;
        }

        & > img {
          max-width: 100%;
        }
      }

      & h2 {
        font-weight: var(--fontWeightBold);
        font-size: 20px;
        line-height: 30px;
        color: var(--colorBlack);

        @media (--viewportLarge) {
          font-size: 22px;
          line-height: 36px;
        }
      }

      & p {
        font-weight: var(--fontWeightRegular);
        font-size: 16px;
        line-height: 21px;
        text-align: left;
        color: var(--colorBlack);
      }
    }
  }

  & .aboutContent {
    position: relative;
  }

  & .shape1 {
    position: absolute;
    left: 0;
    top: 5;
    display: none;

    @media (--viewportMedium) {
      display: block;
    }
  }

  & .shape2 {
    position: absolute;
    right: 0;
    top: 0;
    display: none;

    @media (--viewportMedium) {
      display: block;
    }
  }

  & .shape3 {
    position: absolute;
    left: 0;
    bottom: -74px;
    z-index: 1;
    display: none;

    @media (--viewportMedium) {
      display: block;
    }
  }

  & .shape4 {
    position: absolute;
    right: 0;
    bottom: -74px;
    z-index: 1;
    display: none;

    @media (--viewportMedium) {
      display: block;
    }
  }
}

.answersAboutSection {
  padding: 24px;
  position: relative;
  background-color: var(--matterLandingColor);

  & .bestExpert {
    max-width: 820px;
    padding: 40px 0px;
    width: 100%;
    color: var(--marketplaceColorSecondary);
    font-size: 30px;
    line-height: 40px;
    margin: 0 auto;
    text-align: center;
    font-weight: var(--fontWeightMedium);
    font-family: initial;

    @media (--viewportMedium) {
      font-size: 38px;
      line-height: 48px;
      padding: 40px 20px;
    }

    @media (--viewportLarge) {
      font-size: 48px;
      line-height: 58px;
    }

    & > span {
      background-color: hsl(22deg 82% 73% / 25%);
    }
  }

  & h1 {
    color: var(--marketplaceColor);
    font-weight: var(--fontWeightBold);
    font-size: 35px;
    line-height: 58px;
    text-align: center;
    margin-bottom: 20px;

    @media (--viewportMedium) {
      margin-bottom: 40px;
    }

    @media (--viewportLarge) {
      font-size: 45px;
      line-height: 68px;
    }
  }

  & .splitColumns {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    width: 100%;

    @media (--viewportMedium) {
      flex-direction: row;
    }

    & .left,
    .right {
      flex: 1;
      padding: 20px;
      justify-content: center;
      width: 100%;
      font-size: 32px;
      font-weight: var(--fontWeightBold);

      @media (--viewportMedium) {
        width: 50%;
      }

      & .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 8px;

        & .item {
          border: 1px solid var(--matterPurpleColor);
          border-radius: 48px;
          padding: 16px;
          gap: 8px;

          width: 100%;
          font-size: 22px;
          font-weight: var(--fontWeightMedium);

          @media (--viewportMedium) {
            flex: 0 1 auto;
            width: unset;
          }
        }
      }
    }

    & .left {
      border-bottom: 1px solid var(--colorBlack);

      @media (--viewportMedium) {
        border-bottom: none;
      }

      & h4 {
        color: var(--matterPurpleColor);
      }
    }

    & .right {
      & h4 {
        color: var(--matterTextColor);
      }

      & .content {
        & .item {
          border: 1px solid var(--matterExpertsColor);
        }
      }
    }

    & .divider {
      width: 1px;
      background-color: var(--colorBlack);
      height: auto;
      min-height: 100%;
      display: none;

      @media (--viewportMedium) {
        display: block;
      }
    }
  }

  & .aboutContent {
    position: relative;

    @media (--viewportMedium) {
      padding-bottom: 100px;
    }
  }

  & .shape1 {
    position: absolute;
    left: 0;
    top: 5;
    display: none;

    @media (--viewportMedium) {
      display: block;
    }
  }

  & .shape2 {
    position: absolute;
    right: 0;
    top: 0;
    display: none;

    @media (--viewportMedium) {
      display: block;
    }
  }

  & .shape3 {
    position: absolute;
    left: 0;
    bottom: -74px;
    z-index: 1;
    display: none;

    @media (--viewportMedium) {
      display: block;
    }
  }

  & .shape4 {
    position: absolute;
    right: 0;
    bottom: -74px;
    z-index: 1;
    display: none;

    @media (--viewportMedium) {
      display: block;
    }
  }
}

.healthPlatform {
  width: auto;
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff6f6;
  position: relative;
  text-align: center;
  z-index: 0;

  @media (--viewportMedium) {
    padding: 60px 0;
  }

  & .shape1 {
    position: absolute;
    left: 0;
    bottom: 0;
    display: none;

    @media (--viewportMedium) {
      display: block;
    }
  }

  & .shape2 {
    position: absolute;
    right: 0;
    bottom: 0;
    display: none;

    @media (--viewportMedium) {
      display: block;
    }
  }

  & .contentWidth {
    position: relative;
    z-index: 1;
  }

  & h2 {
    font-weight: var(--fontWeightBold);
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    color: var(--colorWhite);
    margin: 0 auto;
    max-width: 499px;
    width: 100%;

    /* text-transform: uppercase; */
    @media (--viewportMedium) {
      font-size: 30px;
      line-height: 45px;
    }
  }
}

.healthPlatformGreen {
  width: 100%;
  padding: 50px 24px;
  background-color: var(--matterGreenColor);
  position: relative;
  text-align: center;
  z-index: 0;

  @media (--viewportMedium) {
    padding: 100px 24px;
  }

  & .shape1 {
    position: absolute;
    left: 0;
    bottom: 0;
    display: none;

    @media (--viewportMedium) {
      display: block;
    }
  }

  & .shape2 {
    position: absolute;
    right: 0;
    bottom: 0;
    display: none;

    @media (--viewportMedium) {
      display: block;
    }
  }

  & .contentWidth {
    position: relative;
    z-index: 1;
  }

  & h2 {
    font-weight: var(--fontWeightBold);
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    color: var(--colorWhite);
    margin: 0 auto;
    max-width: 750px;
    width: 100%;

    /* text-transform: uppercase; */
    @media (--viewportMedium) {
      font-size: 30px;
      line-height: 45px;
    }
  }
}

.otherDetails {
  padding: 60px 24px;

  & .contentWidth {
    max-width: 751px;
  }

  & .otherinfoBlock {
    display: flex;
    margin-bottom: 30px;
    flex-direction: column;
    text-align: center;

    @media (--viewportMedium) {
      flex-direction: row;
      padding: 30px 0;
      text-align: initial;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:nth-child(1n) {
      & .otherinfoDetails {
        padding-right: 0;
      }
    }

    &:nth-child(2n) {
      & .otherinfoImg {
        @media (--viewportMedium) {
          padding-left: 24px;
        }
      }

      & .otherinfoDetails {
        padding-left: 0;
      }
    }

    & .otherinfoImg {
      width: 100px;
      height: 100px;
      overflow: hidden;
      text-align: center;
      border-radius: 100px;
      margin-bottom: 14px;
      order: 1;
      margin: 0 auto;

      @media (--viewportMedium) {
        margin: 0;
        width: 50%;
        text-align: initial;
        border-radius: 0;
        height: auto;
        order: initial;
        margin-bottom: 0px;
      }

      & > img {
        max-width: 100%;
        object-fit: cover;
        width: 100%;
        height: 100%;

        @media (--viewportMedium) {
          object-fit: initial;
          width: auto;
          height: auto;
        }
      }
    }

    & .otherinfoDetails {
      width: 100%;
      order: 2;

      @media (--viewportMedium) {
        width: 50%;
        padding: 0 24px;
        order: initial;
      }

      & > h2 {
        font-weight: var(--fontWeightBold);
        font-size: 24px;
        line-height: 36px;
        margin: 0;
        color: var(--marketplaceColorSecondary);
      }

      & > p {
        font-weight: var(--fontWeightRegular);
        font-size: 16px;
        line-height: 24px;
        color: var(--colorBlack);
        margin: 0;
      }
    }
  }
}

.registerSection {
  position: relative;
  padding: 50px 24px;
  overflow: hidden;

  @media (--viewportMedium) {
    padding: 120px 24px;
  }
}

.registerButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin: 0 auto;
  position: relative;
  z-index: 1;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.registerButton {
  width: 100%;
  padding: 15px 20px;
  margin-bottom: 20px;
  font-weight: var(--fontWeightSemiBold);
  font-size: 14px;
  line-height: 21px;
  color: var(--colorWhite);
  border-radius: 5px;
  cursor: pointer;
  transition: var(--transitionStyle);
  text-align: center;

  @media (--viewportMedium) {
    width: auto;
    margin: 0 10px;
    margin-bottom: 0;
  }

  &:hover {
    opacity: 0.9;
  }
}

.individualButton {
  background-color: var(--marketplaceColorSecondary);
  border: solid 1px var(--marketplaceColorSecondary);
}

.expertButton {
  background-color: var(--matterYellowColor);
  border: solid 1px var(--matterYellowColor);
}

.companyButton {
  background-color: var(--matterGreenColor);
  border: solid 1px var(--matterGreenColor);
}

.leftImage,
.rightImage {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50%;
  max-width: 200px;
  z-index: 0;

  @media (max-width: 768px) {
    display: none;
  }
}

.leftImage {
  left: -50px;
}

.rightImage {
  right: -50px;
}

.featuresHeader > span {
  font-weight: var(--fontWeightBold);
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  color: var(--colorWhite);
  margin: 0 auto;
  max-width: 750px;
}

.ppt2 {
  width: 100%;
  padding: 50px 24px;
  background-color: var(--matterYellowColor);
  background-size: contain;
  background-position: center;
  position: relative;
  text-align: center;
  z-index: 0;
  overflow: hidden;

  & .contentWidth {
    position: relative;
    z-index: 1;

    @media (--viewportMedium) {
      width: 60%;
    }
  }

  & h2 {
    font-weight: var(--fontWeightMedium);
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    color: var(--colorWhite);
    width: 100%;
    margin: 0;

    @media (--viewportLarge) {
      font-size: 20px;
      line-height: 130%;
    }

    @media (--viewportMLarge) {
      font-size: 24px;
      line-height: 130.5%;
    }
  }

  .registerNow {
    margin-top: 30px;

    & a {
      /* text-transform: uppercase; */
      text-decoration: none;
      font-weight: var(--fontWeightSemiBold);
      font-size: 14px;
      line-height: 21px;
      color: var(--colorWhite);
      padding: 15px 40px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: var(--transitionStyle);
      width: 100%;

      @media (--viewportMedium) {
        display: inline-flex;
        width: auto;
      }

      &:hover {
        transition: var(--transitionStyle);
      }

      &.individual {
        background-color: var(--marketplaceColorSecondary);
        border: solid 1px var(--marketplaceColorSecondary);

        &:hover {
          background-color: var(--colorWhite);
          border: solid 1px var(--colorWhite);
          color: var(--marketplaceColorSecondary);
        }
      }
    }
  }

  & .shape1 {
    position: absolute;
    left: 0;
    top: 0;
    display: none;

    @media (--viewportMedium) {
      display: block;
    }

    & > img {
      max-width: 100%;
    }
  }

  & .shape2 {
    position: absolute;
    right: 0;
    bottom: 0;
    display: none;

    @media (--viewportMedium) {
      display: block;
    }

    & > img {
      max-width: 100%;
    }
  }
}

.hereForYou {
  text-align: center;
}

.registerHealthcare {
  position: relative;

  @media (--viewportLarge) {
    padding: 0px;
  }

  & p {
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    line-height: 130.5%;
    color: var(--colorWhite);
    max-width: 675px;
    width: 100%;
    margin: 0 auto;
    text-align: left;
  }

  & .shape1 {
    position: absolute;
    left: 0;
    bottom: 0;
    display: none;
    text-align: left;

    @media (--viewportMedium) {
      display: block;
    }

    & > img {
      max-width: 70%;
      width: 100%;
    }
  }

  & .shape2 {
    position: absolute;
    right: 0;
    bottom: 0;
    display: none;
    text-align: right;

    @media (--viewportMedium) {
      display: block;
    }

    & > img {
      max-width: 70%;
      width: 100%;
    }
  }

  & .contentWidth {
    position: relative;
    text-align: left;
    z-index: 1;

    @media (--viewportMedium) {
      width: 60%;
    }
  }
}

.services {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;

  @media (--viewportMedium) {
    gap: 16px;
  }

  & a {
    /* text-transform: uppercase; */
    text-decoration: none;
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    line-height: 24px;
    color: var(--colorWhite);
    padding: 18px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: var(--transitionStyle);
    margin-bottom: 20px;
    height: auto;

    @media (--viewportMedium) {
      display: inline-flex;
      margin-right: 20px;
      margin-bottom: 0px;
      width: 223px;
      height: 74px;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      transition: var(--transitionStyle);
    }

    &.individual {
      background-color: var(--matterPurpleColor);
      border: solid 1px var(--matterPurpleColor);
      border-radius: 80px;

      &:hover {
        background-color: var(--colorWhite);
        border: solid 1px var(--matterPurpleColor);
        color: var(--matterPurpleColor);
      }
    }
  }
}

.registerAs {
  text-align: center;
  padding-top: 30px;

  @media (--viewportMedium) {
    padding-top: 40px;
  }

  & a {
    /* text-transform: uppercase; */
    text-decoration: none;
    font-weight: var(--fontWeightSemiBold);
    font-size: 14px;
    line-height: 21px;
    color: var(--colorWhite);
    padding: 15px 20px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: var(--transitionStyle);
    margin-bottom: 20px;
    width: 100%;

    @media (--viewportMedium) {
      display: inline-flex;
      margin-right: 20px;
      margin-bottom: 0px;
      width: 250px;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      transition: var(--transitionStyle);
    }

    &.individual {
      background-color: var(--marketplaceColorSecondary);
      border: solid 1px var(--marketplaceColorSecondary);

      &:hover {
        background-color: var(--colorWhite);
        border: solid 1px var(--marketplaceColorSecondary);
        color: var(--marketplaceColorSecondary);
      }
    }

    &.expert {
      background-color: var(--matterYellowColor);
      border: solid 1px var(--matterYellowColor);

      &:hover {
        background-color: var(--colorWhite);
        border: solid 1px var(--matterYellowColor);
        color: var(--matterYellowColor);
      }
    }

    &.company {
      background-color: var(--matterGreenColor);
      border: solid 1px var(--matterGreenColor);

      &:hover {
        background-color: var(--colorWhite);
        border: solid 1px var(--matterGreenColor);
        color: var(--matterGreenColor);
      }
    }
  }
}

.heroSearchSec {
  padding: 0px;
  margin: 0 auto;
  max-width: 618px;
  width: 100%;
}

.authButtons {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: 12px;

  & > a {
    & button {
      width: 140px;
      border: 1px solid;
    }
  }
}

.serviceSection {
  padding-top: 47px;
  padding-bottom: 47px;
  padding-left: 24px;
  padding-right: 24px;
}

.clientSection {
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 24px;
  padding-right: 24px;

  & .clientLogo {
    background-color: var(--colorWhite);
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border: solid 1px var(--colorGrey100);
    border-radius: var(--borderRadiusLarge);

    & > img {
      width: 100px;
    }
  }

  & :global(.slick-slider) {
    width: 100%;
  }

  & :global(.slick-track) {
    margin: 0;
  }

  & :global(.slick-list) {
    position: relative;
    margin: 0 -10px;

    @media (min-width: 1300px) {
      overflow: hidden;
      margin: 0 -15px;
    }
  }

  & :global(.slick-track) {
    margin: 0;
  }

  & :global(.slick-slide) {
    padding: 0 10px;

    @media (min-width: 1300px) {
      padding: 0 15px;
    }
  }

  & :global(.slick-dots) {
    bottom: -35px;

    & > li {
      margin: 0;

      &:not(:last-child) {
        margin-right: 5px;
      }

      & > button {
        padding: 0;
        border: solid 1px transparent;
        border-radius: 100px;

        &::before {
          font-size: 8px;
          letter-spacing: -0.5px;
          line-height: 23px;
          opacity: 1;
          color: var(--colorGrey200);
        }
      }
    }

    & :global(.slick-active) {
      & > button {
        border: solid 1px var(--marketplaceColor);

        &::before {
          color: var(--marketplaceColor);
        }
      }
    }
  }
}

.sectionOffering {
  padding: 48px 0;
  background-color: #fff6f6;
}

.bannerContainer {
  background-size: cover;
  background-position: center;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bannerText {
  color: white;
  font-size: 2.3rem;
  text-align: center;
  max-width: 600px;
}

.alternatingSection {
  background-color: #fff6f6;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 2rem 0;
  justify-content: center;
  align-items: center;
  padding: 24px;
}

.row {
  display: flex;
  align-items: center;
  gap: 2rem;
  max-width: 1200px;
}

.reverse {
  flex-direction: row-reverse;
}

.imageContainer {
  max-width: 300px;
}

.imageContainer img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

.textContainer {
  max-width: 600px;
}

.textContainer h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.textContainer p {
  font-size: 1rem;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .row,
  .reverse {
    flex-direction: column;
  }

  .imageContainer {
    max-width: 100%;
  }
}
